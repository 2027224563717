<template>
  <nav class="navbar bg-body-tertiary">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions"
        aria-controls="offcanvasWithBothOptions">
        <box-icon name='menu' animation='tada-hover'></box-icon>
      </button>
    </div>
  </nav>
  <aside>
    <div class="offcanvas offcanvas-start" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions"
      aria-labelledby="offcanvasWithBothOptionsLabel" style="overflow-y: scroll; max-height: 100vh;">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasExampleLabel">Menú</h5>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body flex-shrink-0" data-bs-spy="scroll">
        <ul class="list-unstyled">
          <li class="mb-1" v-show="this.statusCompany">
            <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
              data-bs-target="#publicar-collapse" aria-expanded="false">
              Publicar Vacante
            </button>
            <div class="collapse" id="publicar-collapse">
              <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li><router-link to="/becarioform" class="link-dark rounded">Becario
                  </router-link></li>
                <li><router-link to="/egresadosform" class="link-dark rounded">Profesionista
                  </router-link></li>

              </ul>
            </div>
          </li>
          <li class="mb-1" v-show="this.statusCompany">
            <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
              data-bs-target="#orders-collapse" aria-expanded="false">
              Administración
            </button>
            <div class="collapse" id="orders-collapse">
              <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li><router-link to="/vacantes/empresa" class="link-dark rounded">Vacantes</router-link></li>
                <li><router-link to="/talento" class="link-dark rounded">Candidatos</router-link></li>
                <li><router-link to="/administration/mycompany" class="link-dark rounded"
                    v-show="this.mode_admin">Contratados / Aceptados</router-link>
                </li>
              </ul>
            </div>
          </li>
          <li class="mb-1" v-show="this.statusCompany">
            <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
              data-bs-target="#dashboard-collapse" aria-expanded="false">
              Dashboard
            </button>
            <div class="collapse" id="dashboard-collapse">
              <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">

                <li><router-link class="link-dark rounded" to="/dashboard">Monitoreo de Vacantes </router-link></li>
              </ul>
            </div>
          </li>
          <li class="mb-1" v-show="this.statusCompany">
            <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
              data-bs-target="#estadisticos-collapse" aria-expanded="false">
              Estadísticos
            </button>
            <div class="collapse" id="estadisticos-collapse">
              <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">

                <li>
                  <router-link class="link-dark rounded" to="/estadisticos/vacantes-totales">
                    Vacantes Totales
                  </router-link>
                </li>
                <li>
                  <router-link class="link-dark rounded" to="/estadisticos/vacantes-company">
                    Vacantes por Unidad/División
                  </router-link>
                </li>
                <li>
                  <router-link class="link-dark rounded" to="/estadisticos/vacantes-status-pie">
                    Vacantes por Status
                  </router-link>
                </li>
                <li>
                  <router-link class="link-dark rounded" to="/estadisticos/vacantes-area-pie">
                    Vacantes por Área
                  </router-link>
                </li>
                <li>
                  <router-link class="link-dark rounded" to="/estadisticos/vacantes-responsable">
                    Vacantes por Responsable
                  </router-link>
                </li>

                <li>
                  <router-link class="link-dark rounded" to="/estadisticos/vacantes-nivel">
                    Vacantes por Nivel
                  </router-link>
                </li>
                <li>
                  <router-link class="link-dark rounded" to="/estadisticos/vacantes-cierre">
                    Vacantes Cerradas
                  </router-link>
                </li>
                <li>
                  <router-link class="link-dark rounded" to="/estadisticos/vacantes-no-cierre">
                    Vacantes No Cerradas
                  </router-link>
                </li>
                <li>
                  <router-link class="link-dark rounded" to="/estadisticos/vacantes-fin-terna">
                    Vacantes Fin vs Terna
                  </router-link>
                </li>
                <li>
                  <router-link class="link-dark rounded" to="/estadisticos/vacantes-kpi">
                    Vacantes por KPI
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <li class="mb-1" v-show="mode_client">
            <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
              data-bs-target="#administracion-collapse" aria-expanded="false">
              Clientes
            </button>
            <div class="collapse" id="administracion-collapse">
              <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li>
                  <router-link class="link-dark rounded" to="/administration/clients">
                    Administración
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <li class="mb-1" v-show="mode_contributor">
            <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
              data-bs-target="#administracion-collapse" aria-expanded="false">
              Clientes
            </button>
            <div class="collapse" id="administracion-collapse">
              <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li>
                  <router-link class="link-dark rounded" to="/administration/clients/contributors">
                    Consulta de Vacantes
                  </router-link>
                </li>
                <li>
                  <router-link class="link-dark rounded" to="/administration/clients/list/contratados">
                    Consulta de Aceptados / Contratados
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li class="border-top my-3"></li>
          <li class="mb-1">
            <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
              data-bs-target="#account-collapse" aria-expanded="false">
              Cuenta
            </button>
            <div class="collapse" id="account-collapse">
              <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li><router-link to="/empresa/perfil" class="link-dark rounded">Perfil</router-link></li>
                <li><router-link to="/informationCompany" class="link-dark rounded">Configuración</router-link></li>
                <li><a href="#" class="link-dark rounded" @click="logout()">Cerrar Sesión</a></li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </aside>

  <body>
    <router-view />
  </body>


</template>
<script>
import { CompanyService } from "@/services";

export default {
  data() {
    return {
      statusCompany: false,
      mode_client: false,
      mode_contributor: false,
      mode_admin: false,
      id: ""
    };
  },
  created() {
    this.loadUserData();
  },
  mounted() {
    this.setupDropdownPanelState();
  },
  methods: {
    async loadUserData() {
      const dataUser = JSON.parse(localStorage.getItem("user")) || null;
      if (dataUser) {
        this.id = dataUser._id;
        await this.getCompany({ _id: this.id });
      }
    },
    async getCompany(id) {
      try {
        const response = await CompanyService.getCompanyUser(id);
        const data = response.data.data;
        if (data.length > 0) {
          response.data.data.forEach((company) => {
            this.statusCompany = company.status;
            if (company.status === false) {
              this.showModal();
            }
            this.mode_client = company.mode_client;
            this.mode_contributor = company.mode_contributor;
            this.mode_admin = company.mode_admin;
          });
        }
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    },
    showModal() {
      this.$swal({
        title:
          "<h5 style='Poppins'>Tu perfíl se encuentra en revisión, cuando sea aprobado serás notificado al correo electrónico de contacto.</h5>",
        icon: "info",
        confirmButtonText: 'Genial',
        confirmButtonAriaLabel: "Thumbs up, great!",
      });
    },
    setupDropdownPanelState() {
      const dropdownButtons = document.querySelectorAll(".offcanvas-body .btn-toggle");
      dropdownButtons.forEach((button) => {
        button.addEventListener("click", () => {
          const panelId = button.getAttribute("data-bs-target");
          const panelState = button.getAttribute("aria-expanded");
          localStorage.setItem(panelId, panelState);
        });
        const panelId = button.getAttribute("data-bs-target");
        const panelState = localStorage.getItem(panelId);
        if (panelState === "true") {
          button.setAttribute("aria-expanded", "true");
          const panel = document.querySelector(panelId);
          panel.classList.add("show");
        }
      });
    },
    logout() {
      localStorage.removeItem('user');
      localStorage.removeItem('accessToken');
      this.removeCookie("user");
      this.removeCookie("accessToken");
      window.location.href = "/";
    },
    removeCookie(name) {
      document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    }
  }
};

</script>

<style land="scss">
@import "../../styles/empresa.layouts.scss";
</style>